module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-190592718-2"]},
    },{
      plugin: require('../plugins/gatsby-password-protect/gatsby-browser.js'),
      options: {"plugins":[],"password":"1MMC1","partialMatching":true,"pagePaths":["/locked"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
