import { configureStore } from '@reduxjs/toolkit';
import userReducer from './user';
import cartReducer from './cart';
import subModalReducer from './sub_modal';
import hoverableQuestionsReducer from './hoverableQuestions';

const createStore = () =>
  configureStore({
    reducer: {
      user: userReducer,
      cart: cartReducer,
      subModal: subModalReducer,
      hoverableQuestions: hoverableQuestionsReducer,
    },
  });

export default createStore;
