import { createSlice } from '@reduxjs/toolkit';

const isBrowser = typeof window !== 'undefined';

export const hoverableQuestionsSlice = createSlice({
  name: 'hoverableQuestions',
  initialState: {
    questions: [],
  },
  reducers: {
    populate: (state, action) => {
      if (state.questions.length) return;
      state.questions = action.payload;
    },
    startClosing: (state, action) => {
      state.questions = state.questions.map((q, i) => {
        if (i !== action.payload) return q;
        else
          return {
            node: {
              ...q.node,
              closing: true,
            },
          };
      });
    },
    close: (state, action) => {
      state.questions = state.questions.map((q, i) => {
        if (i !== action.payload) return q;
        else
          return {
            node: {
              ...q.node,
              closed: true,
            },
          };
      });
    },
  },
});

export const populate = (questions) => (dispatch) =>
  dispatch(hoverableQuestionsSlice.actions.populate(questions));

export const startClosing = (index) => (dispatch) =>
  dispatch(hoverableQuestionsSlice.actions.startClosing(index));

export const close = (index) => (dispatch) => {
  dispatch(hoverableQuestionsSlice.actions.close(index));
};

export default hoverableQuestionsSlice.reducer;
