/**
 * Write-only the password as cookie
 */
import React, { useState } from 'react';
import { setSessionPassword } from '../utils/utils';

import Logo from '../../../../src/assets/images/logo-small.svg';
import Clouds from '../../../../src/assets/images/clouds.svg';

const styles = {
  button: {
    display: 'block',
    margin: '0 auto',
    width: '100%',
  },
  clouds: {
    bottom: 0,
    zIndex: 0,
  },
};

const PasswordProtect = () => {
  const [password, setPassword] = useState('');

  const onSubmit = event => {
    event.preventDefault();
    setSessionPassword(password);
    window.location.reload(); // eslint-disable-line
  };

  return (
    <div>
      <div className="password-protect-wrapper">
        <img src={Logo} alt="logo" style={{ marginBottom: 30 }} />
        <h1 className="hero-h1">Stay there!</h1>
        <p style={{ marginBottom: 30 }}>
          This folder is locked. Please enter the password below to access it.
        </p>

        <form onSubmit={onSubmit} style={{ width: '320px' }}>
          <input
            className="w-input"
            name="password"
            type="password"
            value={password}
            onChange={event => setPassword(event.target.value)}
          />

          <button type="submit" className="button-blue w-button" style={styles.button}>
            Enter
          </button>
        </form>
        <img src={Clouds} alt="Clouds" className="pattern-left" style={styles.clouds} />
      </div>
    </div>
  );
};

export default PasswordProtect;
